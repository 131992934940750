
import thomas from '../assets/images/Thomas.jpeg'
import nebyat from '../assets/images/Nebyat.jpeg'
import yadu from '../assets/images/Yadu.jpeg'
import feysal from '../assets/images/feysal.jpg'
import omega from '../assets/images/Omega.jpeg'
// import amanti from '../assets/images/Amanti.jpeg'
import Abraham from '../assets/images/Abraham.png'
// import Abd from '../assets/images/abdi.jpg'

const Team = ()=>{

  return (
    <>
    <section id="team" className="team_area pt-120 pb-120">
      <div className="container">
          <div className="row justify-center">
              <div className="w-full lg:w-1/2">
                  <div className="section_title text-center pb-6">
                      <h5 className="sub_title">Team</h5>
                      <h4 className="main_title">Meet Our Team Members</h4>
                  </div>
              </div>
          </div>
          <div className="team-wrapper relative">
              <div className="row team_active">
                  <div className="w-full lg:w-4/12">
                      <div className="single_team_item mx-auto">
                          <div className="single_team mx-3">
                              <div className="team_image relative">
                                  <img src={nebyat} alt="team" className="w-full" />
                                  <ul className="social absolute top-4 right-8">
                                      <li><a href="!#"><i className="lni lni-facebook-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-twitter-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-linkedin-original"></i></a></li>
                                  </ul>
                              </div>
                              <div className="team_content py-5 px-8 h-64 relative">
                                  <h4 className="team_name text-xl md:text-2xl"><a href="!#" className="text-black group-hover:text-white">Nebyat Fikru | Project Manager & Security Engineer</a></h4>
                                  <p className="mt-2 transition-all duration-300 group-hover:text-white">
                                      <span className="font-black">Skills:</span> Lead multiple team on various projects, System Analysis and Design (SDLC, UML...), & Certified LPIC1, CCNA, GIAC Penetration Testing (Ethical Hacking), Cyber Security Chair
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="w-full lg:w-4/12">
                      <div className="single_team_item mx-auto">
                          <div className="single_team mx-3">
                              <div className="team_image relative">
                                  <img src={yadu} alt="team" className="w-full" />
                                  <ul className="social absolute top-4 right-8">
                                      <li><a href="!#"><i className="lni lni-facebook-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-twitter-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-linkedin-original"></i></a></li>
                                  </ul>
                              </div>
                              <div className="team_content py-5 px-8 h-64 relative">
                                  <h4 className="team_name text-xl md:text-2xl"><a href="!#" className="text-black group-hover:text-white">Yadu Hordofa |  Tech Lead  & Senior Fullstack Developer </a></h4>
                                  <p className="mt-2 transition-all duration-300 group-hover:text-white">
                                      <span className="font-black">Skills:</span> Tech lead, Software Design, Automated Testing, PHP/Laravel, Python, Node.js, AWS, 
                                  </p>
                              </div>
                              
                          </div>
                      </div>
                     
                  </div>
                  <div className="w-full lg:w-4/12">
                  <div className="single_team_item mx-auto">
                          <div className="single_team mx-3">
                              <div className="team_image relative">
                                  <img src={feysal} alt="team" className="w-full" />
                                  <ul className="social absolute top-4 right-8">
                                      <li><a href="!#"><i className="lni lni-facebook-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-twitter-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-linkedin-original"></i></a></li>
                                  </ul>
                              </div>
                              <div className="team_content py-5 px-8 h-64 relative">
                                  <h4 className="team_name text-xl md:text-2xl"><a href="!#" className="text-black group-hover:text-white">Feysal Mama | Tech Lead & Senior Fullstack Developer </a></h4>
                                  <p className="mt-2 transition-all duration-300 group-hover:text-white">
                                      <span className="font-black">Skills:</span> Tech lead, ReactJS, Python, VueJs, Nodejs,PHP Laravel, AWS.
                                  </p>
                              </div>
                              
                          </div>
                      </div>
                      </div>
                  
                  

              </div>
          </div>
          <div className="team-wrapper relative">
              <div className="row team_active">
              <div className="w-full lg:w-4/12">
                      <div className="single_team_item mx-auto">
                          <div className="single_team mx-3">
                              <div className="team_image relative">
                                  <img src={thomas} alt="team" className="w-full" width="320px" height="320px" />
                                  <ul className="social absolute top-4 right-8">
                                      <li><a href="!#"><i className="lni lni-facebook-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-twitter-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-linkedin-original"></i></a></li>
                                  </ul>
                              </div>
                              <div className="team_content py-5 px-8 h-64 relative">
                                  <h4 className="team_name text-xl md:text-2xl"><a href="!#" className="text-black group-hover:text-white">Thomas Kagnew | Fullstack and Mobile Developer</a></h4>
                                  <p className="mt-2 transition-all duration-300 group-hover:text-white">
                                      <span className="font-black">Skills:</span> Django, Golang, C++, AngularJs, ReactNative, ReactNative, Flutter, Redux and NodeJS(loopback), 
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              <div className="w-full lg:w-4/12">
                      <div className="single_team_item mx-auto">
                          <div className="single_team mx-3">
                              <div className="team_image relative">
                                  <img src={Abraham} alt="team" className="w-full" />
                                  <ul className="social absolute top-4 right-8">
                                      <li><a href="!#"><i className="lni lni-facebook-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-twitter-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-linkedin-original"></i></a></li>
                                  </ul>
                              </div>
                              <div className="team_content py-5 px-8 h-64 relative">
                                  <h4 className="team_name text-xl md:text-2xl"><a href="!#" className="text-black group-hover:text-white">Abraham Mathewos | Fullstack Developer </a></h4>
                                  <p className="mt-2 transition-all duration-300 group-hover:text-white">
                                      <span className="font-black">Skills: </span> 
                                       Domain-Drive Dev, Hexagonal Architecture, CI/CD, MERN stack, Javascript, React and Python, Google Cloud Platform 
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
             
                  <div className="w-full lg:w-4/12">
                      <div className="single_team_item mx-auto">
                          <div className="single_team mx-3">
                              <div className="team_image relative">
                                  <img src={omega} alt="team" className="w-full" width="320px" height="320px" />
                                  <ul className="social absolute top-4 right-8">
                                      <li><a href="!#"><i className="lni lni-facebook-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-twitter-filled"></i></a></li>
                                      <li><a href="!#"><i className="lni lni-linkedin-original"></i></a></li>
                                  </ul>
                              </div>
                              <div className="team_content py-5 px-8 h-64 relative">
                                  <h4 className="team_name text-xl md:text-2xl"><a href="!#" className="text-black group-hover:text-white">Omega Meseret | DevOps Engineer </a></h4>
                                  <p className="mt-2 transition-all duration-300 group-hover:text-white">
                                      <span className="font-black">Skills:</span> AWS, NixOps with Terraform, Vault, Consul, Loki + Grafana on AWS, Jenkins, Kubernetes, Docker, Prometheus, Helm Charts.
repos & monitoring with Prometheus
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                

              </div>
          </div>
      </div>
  </section>
    </>
  );
}


export default Team
