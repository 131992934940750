import perfonetImage from '../assets/images/about-us.png';

const AboutUs = () => {
  return (
    <>
      <section id="about" className="about_area py-16 bg-gray-50">
        <div className="container mx-auto">
        <div className="relative">
         {/* Adjusted Moving Shapes on the Left Side */}
      <div className="absolute top-10 right-10 w-16 h-16 bg-blue-300 rounded-full opacity-25 animate-floating"></div>
      <div className="absolute top-40 right-24 w-32 h-32 bg-yellow-300 rounded-full opacity-20 animate-floating-slow"></div>
      <div className="absolute top-80 left-5/6 w-20 h-20 bg-green-200 opacity-30 rotate-45 transform animate-floating-fast"></div>
      <div className="absolute top-20 left-2 w-24 h-24 bg-purple-200 opacity-20 rounded-lg animate-floating"></div>
      <div className="absolute top-60 left-60 w-20 h-20 bg-red-200 rounded-full opacity-25 animate-floating-fast"></div>
      <div className="absolute top-32 right-84 w-16 h-16 bg-indigo-200 opacity-20 rotate-12 animate-floating-slow"></div>
      <div className="absolute top-96 right-84 w-24 h-24 bg-teal-200 opacity-30 rounded-full animate-floating"></div>
      <div className="absolute top-48 left-60 w-20 h-20 bg-pink-200 rounded-full opacity-25 animate-floating"></div>

      {/* Moving Shapes on the Right Side for Balance */}
      <div className="absolute bottom-12 right-6 w-32 h-32 bg-yellow-300 rounded-full opacity-20 animate-floating-slow"></div>
      <div className="absolute top-24 right-10 w-16 h-16 bg-green-200 opacity-30 rotate-45 transform animate-floating-fast"></div>
      <div className="absolute bottom-24 right-24 w-24 h-24 bg-purple-200 opacity-20 rounded-lg animate-floating"></div>
      <div className="absolute top-24 right-4 w-12 h-12 bg-red-200 rounded-full opacity-25 animate-floating-fast"></div>
      <div className="absolute bottom-12 right-2 w-16 h-16 bg-indigo-300 opacity-20 rotate-12 animate-floating-slow"></div>
      <div className="absolute top-32 right-1/2 w-20 h-20 bg-blue-100 opacity-20 rounded-full animate-floating"></div>

      
      
            <div className="text-center mb-12">
                <div className="row justify-center">
                <div className="w-full lg:w-1/2">
                    <div className="section_title text-center pb-6">
                        <h5 className="sub_title">About Us</h5>
                        <h4 className="main_title">Discover PerfoNet ICT Solution PLC</h4>
                    </div>
                </div>
                </div>

                <p className="mt-4 text-gray-600 max-w-xl mx-auto">
                Founded in 2018, PerfoNet ICT Solution PLC has been delivering exceptional technology solutions across various sectors, including education, healthcare, and network infrastructure.
                </p>
            </div>

            <div className="flex flex-col md:flex-row gap-8">
                {/* Left Side: Company Image */}
                <div className="w-full md:w-1/2">
                <img src={perfonetImage} alt="PerfoNet Team" className="w-full" />
                </div>

                {/* Right Side: Company Details */}
                <div className="w-full md:w-1/2">

                {/* Mission, Vision, Values */}
                <div className="mt-8">
                    <h4 className="text-2xl font-semibold text-gray-800">Our Mission</h4>
                    <p className="text-gray-600 mt-2">To provide innovative and efficient Information and Communication Technology solutions that enhance our clients' business operations and drive their growth.</p>

                    <h4 className="text-2xl font-semibold text-gray-800 mt-6">Our Vision</h4>
                    <p className="text-gray-600 mt-2">To be a leader in the ICT industry, providing the latest technology solutions that meet the evolving needs of our clients.</p>

                    <h4 className="text-2xl font-semibold text-gray-800 mt-6">Our Core Values</h4>
                    <ul className="bout_list pt-3">
                        <li className="flex mt-5">
                            <div className="about_check">
                                <i className="lni lni-checkmark-circle"></i>
                            </div>
                            <div className="about_list_content pl-5 pr-2">
                                <p><strong>Customer-focused approach:</strong> We prioritize our clients' needs and work to understand their challenges and goals.</p>
                            </div>
                        </li>
                        <li className="flex mt-5">
                            <div className="about_check">
                                <i className="lni lni-checkmark-circle"></i>
                            </div>
                            <div className="about_list_content pl-5 pr-2">
                                <p><strong>Innovation:</strong> We constantly strive to find new and better ways to deliver our services and solutions.</p>
                            </div>
                        </li>

                        <li className="flex mt-5">
                            <div className="about_check">
                                <i className="lni lni-checkmark-circle"></i>
                            </div>
                            <div className="about_list_content pl-5 pr-2">
                                <p><strong>Integrity:</strong> We maintain a high standard of ethics and transparency in all our dealings with clients and stakeholders.</p>
                            </div>
                        </li>

                        <li className="flex mt-5">
                            <div className="about_check">
                                <i className="lni lni-checkmark-circle"></i>
                            </div>
                            <div className="about_list_content pl-5 pr-2">
                                <p><strong>Collaboration:</strong> We work closely with our clients, partners, and employees to create the best outcomes.</p>
                            </div>
                        </li>

            
                        <li className="flex mt-5">
                            <div className="about_check">
                                <i className="lni lni-checkmark-circle"></i>
                            </div>
                            <div className="about_list_content pl-5 pr-2">
                                <p><strong>Empowerment:</strong> We invest in the growth of our employees, providing them with tools and resources to excel.</p>
                            </div>
                        </li>

            
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
      </section>
      {/* CSS for Animation */}
            <style jsx>{`
            .animate-floating {
                animation: float 6s ease-in-out infinite;
            }
            .animate-floating-slow {
                animation: float 8s ease-in-out infinite;
            }
            .animate-floating-fast {
                animation: float 4s ease-in-out infinite;
            }
            @keyframes float {
                0%, 100% { transform: translateY(0); }
                50% { transform: translateY(-20px); }
            }
            `}</style>
    </>
  );
};

export default AboutUs;
