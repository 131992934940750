import { Link } from "react-router-dom"
const ResponsiveHeader =()=>{

    return (
    
        <>
            <div className="absolute right-0  drop-shadow-md z-20 w-2/4 px-5 py-3 duration-300 bg-white lg:w-auto collapse navbar-collapse top-full mt-full lg:static lg:bg-transparent shadow lg:shadow-none h-96 lg:hidden" id="navbarOne">
                <ul id="nav" className="items-center content-start mr-auto lg:justify-end navbar-nav lg:flex">
                    <li className="nav-item ml-5 lg:ml-11">
                    <Link className="page-scroll active" to="/">Home</Link>
                    </li>
                    <li className="nav-item ml-5 lg:ml-11">
                        <a className="page-scroll" href="#about">About</a>
                    </li>
                    <li className="nav-item ml-5 lg:ml-11">
                        <a className="page-scroll" href="#services">Services</a>
                    </li>
                    <li className="nav-item ml-5 lg:ml-11">
                        <a className="page-scroll" href="#work">Projects</a>
                    </li>
                    
                    <li className="nav-item ml-5 lg:ml-11">
                        <Link className="page-scroll" to="/careers">Careers</Link>
                    </li>
                    <li className="nav-item ml-5 lg:ml-11">
                        <a className="page-scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>    
        </>
        
    )

}

export default ResponsiveHeader

