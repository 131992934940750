import shape1 from '../assets/images/shape/shape-1.svg'
import shape2 from '../assets/images/shape/shape-2.svg'
import shape3 from '../assets/images/shape/shape-3.svg'
import shape4 from '../assets/images/shape/shape-4.svg'
import home  from '../assets/images/headers-image.jpg'


const Home = ()=>{
  return(
       <>
       <div id="home" className="header_hero bg-gray relative z-10 overflow-hidden lg:flex items-center">
         <div className="hero_shape shape_1">
             <img src={shape1} alt="shape" />
         </div>
         <div className="hero_shape shape_2">
             <img src={shape2} alt="shape" />
         </div>
        
         <div className="hero_shape shape_3">
             <img src={shape3} alt="shape" />
         </div>
         <div className="hero_shape shape_4">
             <img src={shape4} alt="shape" />
         </div>
         <div className="hero_shape shape_5">
               <img src={shape1} alt="shape" />
         </div>
         <div className="hero_shape shape_6">
             <img src={shape2} alt="shape" />
         </div>
         <div className="hero_shape shape_7">
               <img src={shape4} alt="shape" />
         </div>
         <div className="hero_shape shape_8">
                <img src={shape4} alt="shape" />
         </div>
         <div className="hero_shape shape_9">
             <img src={shape3} alt="shape" />
         </div>
         <div className="hero_shape shape_10">
             <img src={shape3} alt="shape" />
         </div>
    
         <div className="hero_shape shape_11">
             <img src={shape2} alt="shape" />
         </div>
         <div className="hero_shape shape_12">
                <img src={shape4} alt="shape" />
         </div>
         <div className="hero_shape shape_13">
               <img src={shape1} alt="shape" />
         </div>
         <div className="hero_shape shape_14">
             <img src={shape2} alt="shape" />
         </div>
         
         <div className="hero_shape shape_15">
               <img src={shape1} alt="shape" />
         </div>
         <div className="hero_shape shape_16">
               <img src={shape2} alt="shape" />
         </div>

         <div className="container">
             <div className="row">
                 <div className="w-full lg:w-1/2">
                     <div className="header_hero_content pt-150 lg:pt-0">
                         <h2 className="hero_title text-2xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-extrabold">You bring it, <span className="text-theme-color">We build it.</span> </h2>
                         <p className="mt-8 lg:mr-8">Top-notch digital products from an app development company with a focus on your online business profitability.</p>
                         <div className="hero_btn mt-10">
                             <a className="main-btn" href="#contact">Get In Touch</a>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div className="header_shape hidden lg:block"></div>

         <div className="header_image flex items-center">
             <div className="image 2xl:pl-25">
                 <img src={home} alt="" />
             </div>
         </div>
     </div>

       </>

  );
}

export default Home;
