import contact from '../assets/images/contact.svg'

const ContactAs = () => {
  return (
      <>
            <section  className="flex justify-center flex-col md:flex-row pt-42 pb-96 mt-9">
                <div className="w-4/5 md:w-2/5">
                    <div className="image lg:pr-13">
                        <img src={contact}alt="about" />
                    </div>
                </div>

                <div className="ml-4 w-4/5 md:w-2/5" id="contact">
                    <div className="">
                        <div className="">
                            <div className="mt-11">
                                <div className="section_title"> 
                                    <h5 className="sub_title">Contact</h5>
                                    <h4 className="main_title">Get In Touch</h4>
                                <p>Have you got project ideas?, please send us an email at <span className='text-red-600'>info@perfonet.com</span>, we will love to review and get back to you with solutions as soon as possible.</p>

                                 <div className="p-3">
                                    <h4>WhatsApp</h4> phone:- +251-911-777-027 / +251-920-907-434
                                 </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
        </section>
      </>
  );
}

export default ContactAs;
