import React from 'react'
import { Link } from 'react-router-dom'
import icon from '../../assets/images/icon.png'
export const Careers = () => {
  return (
    <div className='h-auto w-4/5 md:w-2/5 mx-auto mt-48'>
      <Link to='/career-detail'>
          <div className='flex flex-col lg:flex-row justify-between bg-gray-40  hover:bg-gray-100  p-6 rounded-md border-b-2 border-gray-200'>
              <div className='flex fle-col'>
                <div className='mr-4 mt-2'>
                  <a  href="https://www.perfonet-tech.com/">
                     <img src={icon} alt="Logo" className="w-14"/>
                  </a>
                </div>
                
                <div >
                    <p className='text-xs font-semibold leading-6 text-gray-900'>PerfoNet PLC</p>
                    <p className="text-[0.9375rem] font-semibold leading-6 text-gray-900">Senior UI/UX Designer</p>
                    
                    <p className='text-xs leading-6 text-gray-600'>Full-Time or Contract </p>
                </div>
              </div>

              <div className='grid ml-16 md:ml-0 md:place-items-center'>
                <p className='text-xs leading-6 text-gray-600'>Remote/Addid Ababa , Ethiopia</p> 
              </div>
         </div>
      </Link>
         
    </div>
  )
}
