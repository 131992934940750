import './App.css';
import Header from './components/header'
import Home from './components/home'
import Services from './components/services'
import AboutUs from './components/about'
import WorkProcess from './components/work-process'
import Projects from './components/projects'
import Team from './components/team'
import ContactAs from './components/contact-us'
import Footer from './components/footer'
import { Routes,Route } from 'react-router';
import { Careers } from './components/careers/Careers';
import { Detail} from './components/careers/Detail';
import { useLocation} from 'react-router-dom';
import './assets/css/animate.css'
import './assets/fonts/lineicons/font-css/LineIcons.css'
import './assets/css/tailwindcss.css'
import Products from './components/products';

function App() {

const location = useLocation()
  return (
    <div className="App">
        <Header />
       {(location.pathname !=='/careers' && location.pathname !=='/career-detail')?
       ( 
         <>
            <Home/>
            <AboutUs/>
            <WorkProcess/>
            <Services/>
            <Projects/>
            <Products/>
            <Team/>
            <ContactAs/>
            <Footer/>
         </>
        ):
       ( 
        <Routes>
          <Route path='/careers' element={<Careers/>} />
          <Route path='/career-detail' element={<Detail/>} />
        </Routes>
      )}
        
    </div>
  );
}

export default App;
