import React from 'react'
import about  from '../assets/images/about.svg'

export const WorkProcess = () => {
  return (
    <>
    <section className="services_area pt-120" id="about">
        <div className="container">
            <div className="row justify-center">
                <div className="w-full lg:w-1/2">
                    <div className="section_title text-center pb-6">
                        <h4 className="main_title">Work Process</h4>
                    </div>
                </div>
            </div>
            <div className="row justify-center">
                <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                    <div className="single_services text-center mt-8 mx-3">
                        <div className="services_icon">
                        <i className="lni lni-school-bench"></i>
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                <path className="services_shape" id="Polygon_12" data-name="Polygon 12" d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z" />
                            </svg>
                        </div>
                        <div className="services_content h-40 mt-5">
                            <h3 className="services_title text-black font-semibold text-xl md:text-3xl">Research</h3>
                            <p className="mt-4">Together with the project stakeholders to prepare a detailed competition analysis and a list of features</p>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                    <div className="single_services text-center mt-8 mx-3">
                        <div className="services_icon">
                        <i className="lni lni-firefox"></i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                <path className="services_shape" id="Polygon_12" data-name="Polygon 12" d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z" />
                            </svg>
                        </div>
                        <div className="services_content h-40 mt-5">
                            <h3 className="services_title text-black font-semibold text-xl md:text-3xl">Prototype</h3>
                            <p className="mt-4">
                            We will create a simulation for your entire app or just one digital interaction. 
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                    <div className="single_services text-center mt-8 mx-3">
                        <div className="services_icon">
                        <i className="lni lni-brush"></i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                <path className="services_shape" id="Polygon_12" data-name="Polygon 12" d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z" />
                            </svg>
                        </div>
                        <div className="services_content h-40 mt-5">
                            <h3 className="services_title text-black font-semibold text-xl md:text-3xl">Build</h3>
                            <p className="mt-4">We will follow professionally managed development cycle and bring you tested and fully functional softwares</p>
                        </div>
                    </div>
                </div>
                <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                    <div className="single_services text-center mt-8 mx-3">
                        <div className="services_icon">
                        <i className="lni lni-support"></i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                <path className="services_shape" id="Polygon_12" data-name="Polygon 12" d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z" />
                            </svg>
                        </div>
                        <div className="services_content h-40 mt-5">
                            <h3 className="services_title text-black font-semibold text-xl md:text-3xl">Support</h3>
                            <p className="mt-4">Ongoing software support and maintenance services include break/fix services, bug fixing, troubleshooting, backup, ongoing guidance, and advisory</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>

      <section id="why" className="about_area pt-120 relative">
        <div className="about_image flex items-end justify-end">
            <div className="image lg:pr-13">
                <img src={about} alt="about"/>
            </div>
        </div>
        <div className="container">
            <div className="row justify-end">
                <div className="w-full lg:w-1/2">
                    <div className="about_content mx-4 pt-11 lg:pt-15 lg:pb-15">
                        <div className="section_title pb-9">
                            <h5 className="sub_title">Why Choose Us</h5>
                            <h4 className="main_title">Your Goal is Our Achievement</h4>
                        </div>
                        <p>We have dedicated development team having vast areas of experience including education, health and other public sectors. We also have expertise with years 
                            of hands on experience. Some of our elements include: </p>
                        <ul className="about_list pt-3">
                            <li className="flex mt-5">
                                <div className="about_check">
                                    <i className="lni lni-checkmark-circle"></i>
                                </div>
                                <div className="about_list_content pl-5 pr-2">
                                    <p>Good understanding of software requirement and optimal design</p>
                                </div>
                            </li>
                            <li className="flex mt-5">
                                <div className="about_check">
                                    <i className="lni lni-checkmark-circle"></i>
                                </div>
                                <div className="about_list_content pl-5 pr-2">
                                    <p>Great Communication</p>
                                </div>
                            </li>
                            <li className="flex mt-5">
                                <div className="about_check">
                                    <i className="lni lni-checkmark-circle"></i>
                                </div>
                                <div className="about_list_content pl-5 pr-2">
                                    <p>On time delivery and continous support</p>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    </>
  )
}


export default WorkProcess