import erp from '../assets/images/erp.png'; // Placeholder images for each product
import stock from '../assets/images/inventory.png';
import school from '../assets/images/school.png';
import hospital from '../assets/images/hospital.png';
import clinic from '../assets/images/clinic.jpg';
import pharmacy from '../assets/images/pharmacy.png';

const Products = () => {
  return (
    <>
      <section id="products" className="products_area py-16 bg-gray-100">
        <div className="container">
          <div className="text-center mb-10">
            <h5 className="text-lg text-gray-600">Our Products</h5>
            <h2 className="text-3xl font-bold text-gray-800">Explore Our Solutions</h2>
            <p className="mt-3 text-gray-500">Tailored software solutions for different sectors to streamline processes and enhance productivity.</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            
            {/* ERP System */}
            <div className="product-card p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={erp} alt="ERP System" className="w-60 h-64 object-cover rounded-t-lg" />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-800">ERP System</h4>
                <p className="text-gray-600 mt-2">Comprehensive solution for resource planning, designed to integrate and automate business operations.</p>
              </div>
            </div>

            {/* Stock Management System */}
            <div className="product-card p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={stock} alt="Stock Management System" className="w-60 h-60 object-cover rounded-t-lg" />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-800">Stock Management System</h4>
                <p className="text-gray-600 mt-2">Track and control inventory with precision to maintain an optimal stock level efficiently.</p>
              </div>
            </div>

            {/* School and College Management System */}
            <div className="product-card p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={school} alt="School Management System" className="w-full h-60 object-cover rounded-t-lg" />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-800">School & College Management System</h4>
                <p className="text-gray-600 mt-2">A robust system for managing academic and administrative functions in educational institutions.</p>
              </div>
            </div>

            {/* Hospital Management System */}
            <div className="product-card p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={hospital} alt="Hospital Management System" className="w-5/6 h-60 object-cover rounded-t-lg" />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-800">Hospital Management System</h4>
                <p className="text-gray-600 mt-2">End-to-end solution for managing hospital operations, enhancing patient care, and ensuring efficiency.</p>
              </div>
            </div>

            {/* Clinic Management System */}
            <div className="product-card p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={clinic} alt="Clinic Management System" className="w-5/6 h-60 object-cover rounded-t-lg" />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-800">Clinic Management System</h4>
                <p className="text-gray-600 mt-2">Streamlined system for clinic operations, focusing on appointment scheduling and patient management.</p>
              </div>
            </div>

            {/* Pharmacy Management System */}
            <div className="product-card p-6 bg-white shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={pharmacy} alt="Pharmacy Management System" className="w-5/6 h-60 object-cover rounded-t-lg" />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-800">Pharmacy Management System</h4>
                <p className="text-gray-600 mt-2">Automated solution for efficient inventory and sales management in pharmacies.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
