import React from 'react'

export const Detail = () => {
  return (
    <div className='h-full w-4/5 md:w-2/5 mx-auto mt-36 mb-12'>

        <div>
          <p className='text-[1.5rem] font-semibold leading-6 text-gray-900'>Requirements and skills</p>
           <div className='ml-9 mt-3'>
             <ul className='list-disc'>
                <li>Proven work experience as a UI/UX Designer or similar role</li>
                <li>Up-to-date knowledge of design software like Adobe Illustrator and Photoshop</li>
                <li>Team spirit; strong communication skills to collaborate with various stakeholders</li>
                <li>
                5+ years experience in software engineering, including concepts like asynchronous programming, closures, types, and ES6
                </li>

                <li>
                2+ years of HTML/CSS experience, including concepts like layout, specificity, cross browser compatibility, and accessibility
                </li>

                <li>
                Familiarity with: React, Redux, Next.js, Apollo GraphQL
                </li>

                <li>
                Experience rapidly creating and iterating on interfaces written in frameworks like Chakra UI and/or Tailwind CSS
                </li>

                <li>
                Experience or some familiarity with Typescript
                </li>
                <li>
                With strong attention to detail, problem-solving and analytical abilities
                </li>

                <li>Highly proficient in full-stack engineering and writing clean, reusable code</li>
               
             </ul>
           </div>

           <div className='mt-6' >
              <p className='text-[1.5rem] font-semibold leading-6 text-gray-900'> What You'll Be Doing</p>
              <div className='ml-9 mt-3'>
                <ul className='list-disc'>
                    <li>Gather and evaluate user requirements in collaboration with product managers and engineers</li>
                    <li>Illustrate design ideas using storyboards, process flows and sitemaps</li>
                    <li>Design graphic user interface elements, like menus, tabs and widgets</li>
                    <li>Build page navigation buttons and search fields</li>
                    <li>Develop UI mockups and prototypes that clearly illustrate how sites function and look like</li>
                    <li>Create original graphic designs (e.g. images, sketches and tables)</li>
                    <li>Prepare and present rough drafts to internal teams and key stakeholders</li>
                    <li>Identify and troubleshoot UX problems (e.g. responsiveness)</li>
                    <li>Conduct layout adjustments based on user feedback</li>
                    <li>Adhere to style standards on fonts, colors and images</li>
                </ul>

              </div>
           </div>


           <div className='mt-6' >
              <p className='text-[1.5rem] font-semibold leading-6 text-gray-900'>The hiring process</p>

              <div className='ml-9 mt-3'>
                <ul className='list-decimal'>
                  <li>Initial video call with CTO and CEO</li>
                  <li>Short take-home project (5-10 hours)</li>
                  <li>Follow-up video call</li>
                  <li>Confirm references</li>
                  <li>Final call with CEO</li>
                </ul>
              </div>
           </div>


           <div className='mt-6'>
              <p className='text-[1.5rem] font-semibold leading-6 text-gray-900'>How to applay</p>
              <div>
                <p  className='pl-9 mt-3'>jus send your cv to <span className=' text-amber-500'>careers@perfonet-tech.com</span></p>
              </div>
           </div>
        
        </div>
      
    </div>
  )
 }
